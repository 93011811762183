.instruct p{
    font-size: 18px;
    text-align: left;
    justify-content: center;
    margin-top: 2rem;
    font-weight: 600;
    
}
.instruct img{
    border-radius: 50%;
    padding: 11px;
   
}
.instruct h3{
    margin-top: 2rem;
    color:#A2230A;
    font-size: 25px;
    text-align: left;
    font-weight: 700
    /* margin-bottom: 1rem; */
}
.instruct{
    box-shadow: 0px 1px 4px 1px #613b3b3b;
}