.wake{
    background-color: rgba(255, 255, 0, 0.422);
    height: 50vh;
    color:rgb(17, 17, 48);
}
h2{
    text-align: center;
    letter-spacing: .4rem;
    font-weight: 800;
    font-size: 50px;
    padding-top: 30px;
}
