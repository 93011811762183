.program h1{
    margin-bottom: 2rem;
    margin-left: 25%;
    /* margin-top: 2rem; */
    font-weight: 700;
}
.programm{
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 50%;
    text-align: center;
    justify-content: center;
    margin-left: 24%
}
.program img{
    margin-left: 25%;
}
.program p{
    font-size: 18px;
    margin-left: 25%;
    margin-top: 1rem;
}
hr{
    width: 10%;
    margin-left: 25%;
}
.list{
    margin-left: 25%;
    font-size: 20px;
}
.join-now{
    margin-top: 2rem;
    font-size: 22px;
    width: 70%;
    height: 2.3rem;
    background-color: rgb(103, 247, 199);
    border-color: rgb(103, 247, 199);

}