.consultImage{
    background-image: url(../../public/images/consult2.webp);
    height: 150vh;
    width: 100%;
    padding: 2rem;
    object-fit: fill;
    margin-bottom: -2rem;

}
.consult-icon img{
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 35%;
    margin-top: 1%;
}
.consult-btn{
    outline: none;
    border-color: rgb(103, 247, 199);
    color: #ffffff;
    background-color: rgb(103, 247, 199);
    width: 10rem;
    height: 4rem;
    letter-spacing: .2rem;
    font-size: 20px;
    text-align: center;
    border-radius: 8px;
    font-weight:700;
    margin-top: 5%;
    margin-left: 3%;
    /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
     */
     box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    
}
.consultant
{
    text-align: center;
    /* justify-content: center; */
    align-content: center;
    gap: 9%;
}
.consult{
    width: 50%;
    text-align: center;
    font-size: 25px;
    margin-top: 2rem;
    margin-bottom:2rem;
    margin-left: 27%;
    color: #ffffff;
    margin-top: 6rem;
}
.consults h2{
    color: #ffffff;
    font-size: 70px;
    font-weight: 500;
    margin-left: 5%;
    margin-top: 3rem;
}