 .navContent ul{
    display: flex;
   justify-content: flex-end;
   gap: 1rem;
}
.navContent{
    justify-content: flex-end;
}
.navb-head{
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 2;

}
.navContent li{
    list-style: none;
    font-size: 1.2rem;
    color: black;
    font-weight: 550;
      justify-items: flex-end;
      position: relatve;
      cursor: pointer;
      padding: 4px;
    
}
.navContent li:hover{
    background-color: aquamarine; 
    border-radius: 9px;
}
.header{
    display: flex;
    background-color: #fff;
    justify-content: space-between;

}
.header img{
    margin-left: 1rem;
    height: 50%;
    width: 20%;
    margin-top: 2rem;
}
.navContent .drop-down{
    height: auto;
    min-width:10rem ;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: absolute;
    transform: translateX(-20%);
    padding: 1rem 0;
    gap: 1rem;
    top: 4.3rem;
     visibility: hidden;
    opacity: 0;
    z-index: 2;
    box-shadow: 1px 1px 3px 0px  #198754bf;
  
}
.navContent .drop-down li{
    width: 100%;
    text-align: center;
    border-radius: 1px solid aquamarine;
    font-size: 1rem ;

}
.navContent{
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.navContent li:hover > .drop-down {
    visibility: visible;
    opacity: 1;

}
.Link{
    text-decoration: none;
    color: #000000;
}
/* .menu{
    display: none;
} */
.sidenavbar{
    overflow: hidden;
    display: block;
}
@media(max-width:1024px){
    .hideOnMobile-item{
        display: none;
    }
   .menu{
        display:block;
    }
   
}
@media(max-width:400px){
    .sidenavbar-ul{
        width: 100%;
    }
    .menu{
        display: block;
    }
}
@media(max-width:800px){
    .hideOnMobile{
        display: none;
    }
    .menu{
        display: block;
    }
}
@media(max-width:600px){
    .hideOnMobile{
        display: none;
    }
    .menu{
        display: block;
    }
}
.sidebar{
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    z-index: 999;
    background-color: rgba(229, 214, 214, 0.304);
    backdrop-filter: blur(10px);
    box-shadow: -10px 0 10px rgba(0,0, 0, 0.1 );
    /* display:none; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.sidebar li{
    width: 100%;
    list-style: none;
}
.sidebar Link{
    width: 100%;
}
.menu{
    display: none;
    margin-left: auto;
}
@media(max-width: 1023px){
    .hideOnMobile{
        display: none;
    }

    .menu{
        display: flex;
        justify-items: end;
    }
}
@media(max-width: 400px)
{
    .sidebar{
        width:100%;
    }
    .menu{
        display: flex;
    }
}

