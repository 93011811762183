.Signup h1{
    text-align: center;
    justify-content: center;
    font-size: 39px;
    font-weight: 700;
    margin-top: 1rem;
}
.signup-details {
    font-size: 25px;
    text-align: center;
    justify-content: center;
    margin-left: 2%;
}
.Signup {
    
    justify-content: center;
    justify-items: center;
    align-content: center;
}
.Signup span{
    font-size: 25px;
    margin-left: 1rem;
}
.signup-start {
    box-shadow: 0px 1px 7px 0px #00000054;
     margin-top: 1rem;
     height: 615px;
     padding: 2rem;
}
.account {
    font-size: 20px;
}

.input-label input {
    height: 2.5rem;
    width: 24vw;
    outline: none;
    justify-content: center;
    padding: 8px;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.signupImage img {
    height: 30vw;
    margin-top: 5rem;
}


.input-labelled {
    margin-left: 1rem;
    margin-bottom: 1rem;

    label {
        font-size: 18px;
        /* padding-right: 4px; */
    }
}

.input-labelled input {
    height: 1rem;
    width: 1rem;
    margin-right:3px;
    /* margin-left: 1rem; */
    /* margin-top: 5px; */
    margin-bottom: 1rem;
}

.submit-button {
    width: 10vw;
    height: 2rem;
    font-size: 20px;
    border-radius: 12px;
    background-color: rgb(103, 247, 199);
    outline: none;
    margin-left: 8rem;
    border: 0;
    margin-bottom: 1rem;
 
}
.Signup-box{
    margin-bottom: 1rem;
    margin-top: 0%;
}
.link{
    text-decoration: none;
    color: rgb(103, 247, 199);
    font-weight: 650;
}