.Login h1 {
    text-align: center;
    justify-content: center;
    font-size: 39px;
    font-weight: 700;
    /* color: #fff; */
    margin-top: 1rem;
}

.Login-details {
    font-size: 25px;
    text-align: center;
    justify-content: center;
    /* color: #fff; */
}
.form-error{
    color: red;
    font-size: 15px !important;
    margin-top: -1rem;
}
.Login {
    
    justify-content: center;
    justify-items: center;
    align-content: center;
}
.Login span{
    font-size: 25px;
    margin-left: 1rem;
}
.loginStart {
    box-shadow: 0px 1px 7px 0px #00000054;
    /* margin-left: 3rem; */
    margin-top: 2rem;
     height: 530px;
     padding: 2rem;
     /* width: 50%; */
}

.input-label input {
    height: 2.5rem;
    width: 24vw;
    outline: none;
    justify-content: center;
    padding: 8px;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.loginImage img {
    height: 30vw;
    /* width: auto; */
    /* width: 35vw; */

}
.input-labels {
    margin-left: 1rem;

    label {
        font-size: 18px;
    }
}

.input-labels input {
    height: 1rem;
    width: 1rem;
    margin-right: 6px;
    /* margin-left: 1rem; */
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.submit-button {
    width: 10vw;
    height: 2rem;
    font-size: 20px;
    border-radius: 12px;
    background-color: rgb(103, 247, 199);
    outline: none;
    margin-left: 8rem;
    border: 0;
    margin-bottom: 1rem;
    /* font-weight: 600; */
}
.link{
    text-decoration: none;
    color: rgb(103, 247, 199);
    font-weight: 600;
}
.account {
    font-size: 20px;
}