.plan h1{
    text-align: center;
    font-weight: 700;
    margin-top: 3rem;
    font-size: 4rem;
}
.row{
    display: flex;
}
.planDetail ul li{
    list-style: none;
    padding: .5rem;
}
.doIt{
    width: 11rem;
    border: 3px solid rgb(103, 247, 199) ;
    background-color: #fff;
    border-radius: 8px;
}
.value{
    background-color: rgb(103, 247, 199);
    color: #000;
    position: absolute;
    height: 2.3rem;
    width: 7rem;
    padding-top: 5px;
    margin-right: 43rem;

}
.planDetail{
    margin-top: 1.5rem;
    padding-top: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
    box-shadow: 1px 2px 5px 1px #5f9ea0ab;
    border-radius: 8px;
    
}
.planDetail span{
    font-size: 17px;
}